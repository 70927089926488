import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Just blame the cache`}</h1>
    <p>{`This is the story about how Netlify caching bit me in the ass. I was trying to add firestore, a google database, to `}<a parentName="p" {...{
        "href": "https://findcommunities.today"
      }}>{`Find Communities Today`}</a>{` because I wanted to add voting and comments to the communities to give users a better view/opinion of a community.`}</p>
    <p>{`The database document would look pretty simple. It would only have the name of the community, the number of likes, the number of dislikes and finally an array with all the comments. `}</p>
    <p>{`I spent a few hours reading the firebase/firestore documentation and how to include it into gatsby. Since there was a `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-source-firestore/?=firestore"
      }}>{`plugin`}</a>{` for it already, it was easy to make firestore work with Find Communities Today. After reading the firestore documentation I was able to write a few lines of code quickly to create the document into the database when Node creates the pages for each community.`}</p>
    <p>{`I've run the command `}<inlineCode parentName="p">{`gatsby develop`}</inlineCode>{` and surprise! Everything worked just fine!`}</p>
    <p>{`The firestore had now 120 documents - one for each community. I was surprised that the code has worked the first time and I was pretty happy.`}</p>
    <p>{`I've played around and tested things and I could get the number of votes and comments on a community. Now all I had to do was to figure out how to update the document and things would work perfectly.`}</p>
    <h2>{`Then everything went wrong!`}</h2>
    <p>{`The voting system was already built as a component so the logical step would be to use this component to not only show the votes but also get the vote from a user. To do this I would have to call the database and update the document to increase the like/dislike vote.`}</p>
    <p>{`Following the documentation for Node, I was able to write a quick code that should update the database. Since I had to use my API key to initialize the firestore SDK I have decided to use `}<a parentName="p" {...{
        "href": "https://dev.to/fabiorosado/hide-your-api-keys-with-netlify-functions-93m"
      }}>{`Netlify functions to hide the API Key`}</a>{`.`}</p>
    <p>{`I have tried to use `}<inlineCode parentName="p">{`netlify cli`}</inlineCode>{` and `}<inlineCode parentName="p">{`netlify lambda`}</inlineCode>{` to test my function but for some reason, I was getting a 404 error. I still don't know why this was happening.`}</p>
    <p>{`Since I couldn't test the netlify function locally I have decided to give it a go and push the changes to try them in production - the site hasn't been that much active and netlify won't publish the latest push if the build fails.`}</p>
    <p>{`Everything went okay but when I tried to vote nothing has happened. No message got logged and the document remained the same. Since this was the first time I was playing with databases and firestore I just tried a different approach.`}</p>
    <p>{`That day I stayed awake until 3 am trying to fix the netlify function without any success.`}</p>
    <p>{`Okay, I wasn't expecting that the code would work first time again. But after following all the suggestions on StackOverflow and the documentation nothing was working.`}</p>
    <h2>{`Testing Locally`}</h2>
    <p>{`The next day I tried to test the function locally using node. Netlify would use Node to run the server-side functions so running the code on my machine should work in production. The code ran without any issues locally, but on Netlify it didn't.`}</p>
    <p>{`Since I couldn't think what was broken I decided to create a new function and write a lot of `}<inlineCode parentName="p">{`console.logs`}</inlineCode>{`. If I figured out where the function was stopping, I could pin-point where things were broken.`}</p>
    <p>{`Pushing this new function somehow showed me a new error. I couldn't use firestore SDK with node version less than 8.10 (or something like that). So after a quick search online it seemed I couldn't use the firestore SDK with netlify functions because of the node version.`}</p>
    <p>{`I wonder why the function didn't complain about this the first time I tried to push the firestore SDK, but maybe I was doing something wrong.`}</p>
    <h2>{`MongoDB Atlas to the rescue`}</h2>
    <p>{`Since I couldn't use google's firestore as my database I had to look for something else. The `}<a parentName="p" {...{
        "href": "https://findcommunities.today/communities/makerlog"
      }}>{`Makerlog Telegram channel`}</a>{` told me that they moved to MongoDB Atlas after struggling with firestore. So, I decided to give it a go since MongoDB is a pretty easy database to query and I had used it in the past while following a tutorial.`}</p>
    <p>{`After spending so many days fighting with firestore it felt good to just start from scratch and use another technology. The `}<a parentName="p" {...{
        "href": "https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-source-mongodb"
      }}>{`mongodb plugin`}</a>{` worked flawlessly and I was able to create a new document on my MongoDB atlas as easy as before with firestore. `}</p>
    <p>{`I decided to give it a go and write a quick function so I could use node and test to see if I understood the documentation and if I was able to update a document. All worked fine.`}</p>
    <p>{`Since it worked okay I decided to turn that function into a netlify function so I could use it on the real site. After pushing the changes the database would be called but I couldn't update the document.`}</p>
    <p>{`Again, I fiddled with stuff with netlify and noticed that even though I was submitting new changes to the function I kept getting the same old logs. It was as if the function wasn't updated. `}</p>
    <p>{`I've heard tales of how much Netlify caches stuff and I think I have been struck by something like that. After changing the name of my function, I got a different log but the function still wasn't working.`}</p>
    <p>{`I went back to node and ran my netlify function with a few changes - I added a variable that should update a document on the database. Node complained because I wasn't using `}<inlineCode parentName="p">{`await`}</inlineCode>{` in an async function. This seems pretty obvious but remember that I was struggling with databases for almost a week and my head was a mess.`}</p>
    <p>{`After fixing that issue I was able to make things work and finally get the vote and update the document on the database.`}</p>
    <p>{`Afterwards, it was pretty easy to implement the commenting system.`}</p>
    <h2>{`Lesson learned`}</h2>
    <p>{`I've learned a good lesson with this experience. Sometimes cache bites you in your ass and you should plan for it. Also, make sure you check your async code when using `}<inlineCode parentName="p">{`.then()`}</inlineCode>{` and that in some places like netlify functions you won't get any info when you mess your async code. `}</p>
    <p>{`Finally, thank God node did help and warned me of the async issue otherwise I would still be trying to fight with the function!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      